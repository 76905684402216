<template>
    <div class="p-4  h-full flex flex-col justify-between">
        <div class="flex justify-between items-center mb-4">
            <h2 class="text-4xl font-bold mr-8 text-left">{{$t('translations.views.transaction.transaction.title')}}</h2>
            <div class="screen-close" v-if="transaction">
                <modal-transaction-cancel :transaction_id="transaction.id"
                                          :amount_paid="amount_paid" />
            </div>
        </div>

        <div class="flex-grow content-wrapper">
            <div class="flex flex-col justify-between h-full" v-if="initialized" v-cloak>

                <div class="sm:grid sm:grid-cols-3 sm:gap-10 flex-grow overflow-y-auto mobile-flexer">
                    <!-- cash_inout -->
                    <div class="col-span-1" v-if="transaction && transaction.attributes.type == 'cash_inout'">
                        <!--                        <div>-->
                        <!--                            <div class="w-full flex h-10 sm:px-0">-->
                        <!--                                <div class="w-full flex justify-center">-->
                        <!--                                    <h2 class="text-4xl font-bold" v-if="amount"><currency/>{{ vueNumberFormat(amount) }}</h2>-->
                        <!--                                </div>-->
                        <!--                            </div>-->
                        <!--                        </div>-->
                        <numpad :default="amount" :formatted="true"
                                readonly="readonly"
                                @inputchanged="amount = parseFloat($event / 100);"
                                class="numpad-wrapper" />
                    </div>


                    <div v-if="transaction && transaction.attributes.type == 'postransaction'" id="transaction"
                         class="list-secondary list-checkout content-wrapper col-span-2 flex flex-col justify-between flex-grow">
                        <div class="list-items overflow-y-auto scrollbar-hide">
                            <div class="list-item grid grid-cols-12"
                                 v-for="(line, index) in transaction.relationships.lines_consolidated" :key="index">
                                <div class="col-span-2 flex jusfity-center pr-2 items-center">
                                    <div class="leading-5">{{ parseFloat(line.attributes.quantity) }}</div>
                                </div>
                                <div class="col-span-7 text-left flex items-center justify-start">
                                    <div class="font-bold leading-5 truncate"
                                         :class="{'ml-2 italic': line.attributes.addon}">{{
                                            line.attributes.description
                                        }}
                                    </div>
                                    <div class="italic"
                                         v-for="(value, description) in line.attributes.kitchen_groceries"
                                         :key="description">
                                        {{ description }}: {{ value }}
                                    </div>
                                </div>
                                <div class="col-span-3 flex items-center justify-end font-bold">
                                    <div class="flex items-center justify-end flex-wrap-reverse">
                                        <div class="text-xs text-gray-300"
                                             v-if="line.attributes.discount_amount > 0 && active_discount === 'discount_percentage_fixed'">
                                            (-{{ vueNumberFormat(line.attributes.discount_amount) }})
                                        </div>

                                        <div v-if="active_discount === 'discount_amount_fixed'">
                                            {{ vueNumberFormat(line.attributes.total + line.attributes.discount_amount) }}
                                        </div>

                                        <div v-else class="flex items-center justify-end flex-wrap ml-2">
                                            {{ vueNumberFormat(line.attributes.total) }}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <!-- payments -->
                        <div id="transaction" class="transaction-total">
                            <div class="flex items-center justify-between mb-4 border-b pb-4 text-xl done-payments"
                                 v-for="(line, index) in transaction.relationships.payments.filter(pt => {
                                    return pt.attributes.status !== 'failed';
                                })" :key="index">
                                <h3 class="leading-5 truncate ">{{ line.attributes.paymenttype }}</h3>
                                <span>{{ line.attributes.amount }}</span>
                            </div>

                            <div v-if="active_discount === 'discount_amount_fixed'"
                                class="text-right text-xl mb-8"
                            >

                                - <currency/>{{ vueNumberFormat(transaction.attributes.discount_amount) }}
                            </div>

                            <div class="overflow-auto rounded-sm mt-2">
                                <div class="flex items-center justify-start font-bold"
                                     :class="{'justify-between': terminal.layout === 'mobiledirect' || terminal.layout === 'mobileordering'}">
                                    <h3 class="mobile-display-item">Totaal:</h3>
                                    <div v-if="terminal.layout !== 'mobiledirect' && terminal.layout !== 'mobileordering' && transaction.attributes.total_paid > 0">
                                        <span class="text-2xl opacity-80">
                                            <currency/>{{ vueNumberFormat(transaction.attributes.total) }}  -
                                            <currency/>{{ vueNumberFormat(transaction.attributes.total_paid) }}
                                            </span>

                                    </div>

                                    <div class="text-3xl ml-auto"> <currency/>{{ vueNumberFormat(transaction.attributes.balancedue) }}</div>
                                    <!-- <div class="text-xs text-gray-300" v-if="transaction.attributes.discount_amount > 0">(-{{ vueNumberFormat(transaction.attributes.discount_amount) }})</div> -->
                                </div>
                            </div>

                            <!-- <div v-if="transaction.attributes.discount_amount > 0">
                                <div class="overflow-auto hide rounded-sm mt-2" v-if="transaction.attributes.total_paid > 0">
                                    <div class="flex items-center justify-end font-bold text-3xl">
                                        <div> <currency/> {{ vueNumberFormat(amount) }}bb</div>
                                    </div>
                                </div>
                            </div>

                            <div v-else>
                                <div class="overflow-auto rounded-sm mt-2" v-if="transaction.attributes.total != amount">
                                    <div class="flex items-center justify-end font-bold text-3xl">
                                        <div> <currency/> {{ vueNumberFormat(amount) }}cc</div>
                                    </div>
                                </div>
                            </div> -->
                        </div>
                    </div>

                    <!--                                                &lt;!&ndash; payments &ndash;&gt;-->
                    <!--                                                <div id="transaction" class="overflow-auto rounded-sm px-2">-->
                    <!--                                                    <div class="grid grid-cols-12 py-1 flex items-start text-sm italic"-->
                    <!--                                                         v-for="(line, index) in transaction.relationships.payments" :key="index">-->
                    <!--                                                        <div class="col-span-9 flex items-center justify-end">-->
                    <!--                                                            <div class="leading-5 truncate ">{{ line.attributes.paymenttype }}</div>-->
                    <!--                                                        </div>-->
                    <!--                                                        <div class="col-span-3 text-xs flex items-center justify-end">-->
                    <!--                                                            {{ line.attributes.amount }}-->
                    <!--                                                        </div>-->
                    <!--                                                    </div>-->
                    <!--                                                </div>-->


                    <!-- postransaction -->
                    <div class="col-span-1 overflow-y-auto payment-section-checkout mb-8">
                        <div class="checkout-extras">
                            <div v-if="transaction.attributes.type == 'postransaction'" class="split-section">
                                <div
                                    class="w-full flex sm:px-0 mb-4 py-4 flex items-center flex-row-reverse justify-between">
                                    <div>
                                        <div class="hidden sm:block"></div>
                                        <div v-if="terminal.layout !== 'mobiledirect' && terminal.layout !== 'mobileordering'"
                                             class="justify-start sm:justify-center items-center"
                                             :class="{'flex': terminal.layout !== 'mobiledirect'}">
                                            <strong class="mobile-display-item">{{$t('translations.views.transaction.transaction.open')}}: </strong>
                                            <h2 class="text-3xl font-bold" v-if="amount">
                                                <!--                                            {{ transaction }}-->
                                                <currency/>{{ vueNumberFormat(amount) }}
                                            </h2>
                                        </div>
                                    </div>
                                    <div class="flex items-center split-checkout w-1/4">
                                        <modal-split-amount :default="amount" @amountchanged="amount = $event" :button_classes="'button button-blur button-wrapper'" />
                                    </div>
                                </div>
                            </div>

                            <div v-if="transaction.attributes.type == 'postransaction' && terminal.admin_mode"
                                 class="discount-section">
                                <div v-if="transaction.relationships.payments.length == 0"
                                     class="flex justify-start items-center my-2 mb-8 sm:h-10">
                                    <div class="flex justify-start items-center ">
                                        <div class="flex justify-start items-center">
                                            <div v-for="(discount, index) in discounts" v-bind:key="index" class="">
                                                <a @click="discountApply(discount)" class="button button-blur mr-4"
                                                    :class="{'active-discount': discount.id === active_discount_id}"
                                                >
                                                    {{ discount.attributes.description_keyboard }}
                                                </a>
                                            </div>
                                        </div>
                                        <div>
                                            <span class="w-full inline-flex rounded-sm shadow-sm mb-1 sm:mb-0 clear-btn">
                                                <a @click="discountClear()" class="button button-normal">
                                                    {{$t('translations.views.transaction.transaction.clear')}}
                                                </a>
                                            </span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div class="mt-4 mobile-button-section-wrapper">

                            <div class="mobile-total" @click="toggleFoldCheckoutExtras()">
                                <div class="fold-out">
                                    <svg viewBox="0 0 24 24"
                                         xmlns="http://www.w3.org/2000/svg" fill="transparent" stroke="white"
                                         stroke-width="2" stroke-linecap="round" stroke-linejoin="round"
                                         class="h-7 w-7">
                                        <polyline points="18 15 12 9 6 15"></polyline>
                                    </svg>

                                </div>
                                <h2 class="text-3xl font-bold" v-if="amount">
                                    <!--                                            {{ transaction }}-->
                                    <currency/>{{ vueNumberFormat(amount) }}
                                </h2>
                            </div>

                            <div class="flexer">
                                <!-- <h3 class="text-xl">selecteer betaalmethode</h3> -->
                                <div v-for="(paymenttype, index) in paymenttypes" v-bind:key="index"
                                     class="checkout-button button button-large button-wrapper mt-4"
                                     :class="classExtraction(paymenttype)"
                                >
                                    <a v-if="paymenttypeAvailable(paymenttype)" @click="paymentGoto(paymenttype)"
                                       class="button-anchor">
                                        {{ paymenttype.attributes.name }}
                                    </a>
                                </div>
                            </div>
                        </div>


                    </div>
                </div>


                <modal-account ref="account" @selected="transactionSetAccount($event)" />
                <modal-paymenttype-confirm ref="paymenttypeconfirm" @confirmed="paymentGoto($event)" />
                <modal-booking ref="booking" @selected="transactionSetBooking($event)" />
                <modal-wallet-payment ref="walletpayment" @confirmed="transactionSetWallettoken($event)" :allow-scanner="true" />
                <!-- @selected="transactionSetBooking($event)" /> -->
            </div>
        </div>
    </div>

</template>

<script>
import axios from 'axios';
import { setupCache } from 'axios-cache-adapter';
import * as _transaction from '../../store/transaction.js';

// components
import ModalAccount from '../../components/ModalAccount';
import ModalBooking from '../../components/ModalBooking';
import ModalPaymenttypeConfirm from '../../components/ModalConfirmPaymenttype';
import ModalSplitAmount from '../../components/ModalSplitAmount';
import ModalTransactionCancel from '../../components/ModalTransactionCancel';
import ModalWalletPayment from '../../components/ModalWalletPayment';
import Numpad from '../../components/Numpad';

// todo: make global available
const cache = setupCache({
    maxAge: 15 * 60 * 1000,
});
const axiosCache = axios.create({
    adapter: cache.adapter,
});

export default {
    name: 'Transaction',
    // props: ['paymenttype_id'],
    components: {
        ModalAccount,
        ModalBooking,
        ModalPaymenttypeConfirm,
        ModalSplitAmount,
        ModalTransactionCancel,
        ModalWalletPayment,
        Numpad,
    },
    data () {
        return {
            initialized: false,
            transaction: null,
            discount: null,
            paymenttypes: {},
            discounts: {},
            amount: null,
            active_discount: null,
            active_discount_id: null,
            amount_paid: '',
            balancedue: null,
            account_has_asked: false,
        };
    },
    async mounted () {
        // cancel mutation when mutation_id is set
        this.mutationCancel(this.$store.getters['payment/mutation_id']);

        // has transaction_id then...
        if (!this.$store.getters['transaction/id']) {
            await this.transactionInit();
        } else {
            await this.transactionGet();

            // get the paymenttypes
            await this.paymenttypesGet();
            await this.discountsGet();

            await this.amountGet();
        }
    },

    methods: {

        async amountGet () {

            if (this.$store.getters['transaction/type'] == 'cash_inout') {
                this.amount = this.$route.params.amount;
                this.balancedue = this.$route.params.amount;
                this.amount_paid = 0;
                return;
            }

            await axios.get('/transactions/' + this.$store.getters['transaction/id'] + '/amounts').then(response => {
                console.log(response);
                this.amount = response.data.balancedue;
                this.balancedue = response.data.balancedue;
                this.amount_paid = response.data.amount_paid;
            }).catch(() => {
                this.$notify({
                    group: "notifications",
                    type: 'error',
                    title: "Fout opgetreden",
                    text: "Oeps er ging iets fout..",
                }, 2000);
            });
        },

        toggleFoldCheckoutExtras () {
            let tableList = document.querySelector('.payment-section-checkout');
            tableList.classList.toggle("folded-open");
        },

        classExtraction (item) {
            if (item.attributes.preferred_payment === true) {
                return 'button-filled';
            }

            return 'button-ghost';
        },

        async discountApply (discount) {

            console.log(this.transaction);

            this.active_discount = discount.attributes.type;
            this.active_discount_id = discount.id;

            await axios.post('/transactions/' + this.transaction.id + '/discount', {
                discount_id: discount.id,

            }).then(response => {
                this.discount = discount;
                this.transaction = response.data.data;

            }).catch(() => {
                this.$notify({
                    group: "notifications",
                    type: 'error',
                    title: "Fout opgetreden",
                    text: "Oeps er ging iets fout..",
                }, 2000);
            });

            await this.amountGet();
        },

        async discountClear () {
            this.active_discount = null;
            this.active_discount_id = null;

            await axios.post('/transactions/' + this.transaction.id + '/discount/clear', {}).then(response => {
                this.discount = null;
                this.transaction = response.data.data;

            }).catch(() => {
                this.$notify({
                    group: "notifications",
                    type: 'error',
                    title: "Fout opgetreden",
                    text: "Oeps er ging iets fout..",
                }, 2000);
            });

            await this.amountGet();
        },

        async discountsGet () {

            let dataDiscounts = JSON.parse(localStorage.getItem('masterdata.discounts'));

            if (!dataDiscounts) {
                try {
                    const response = await axios({
                        url: '/discounts',
                        method: 'get',
                    });

                    dataDiscounts = response.data.data;
                    localStorage.setItem('masterdata.discounts', JSON.stringify(dataDiscounts));

                } catch (error) {
                    localStorage.removeItem('masterdata.discounts');
                    console.error(error);
                    return this.$notify({
                        group: "notifications",
                        type: 'error',
                        title: "Fout opgetreden",
                        text: "Oeps er ging iets fout..",
                    }, 2000);
                }
            }
            this.discounts = dataDiscounts;
        },

        async mutationCancel (mutation_id) {

            if (mutation_id) {
                axios.post('/mutation/cancel', {
                    mutation_id: mutation_id,
                }).then(response => {
                    if (response.data.result == 'failed') {
                        this.$notify({
                            group: "notifications",
                            type: 'error',
                            title: "Fout opgetreden",
                            text: response.data.message,
                        }, 2000);
                    }
                }).catch(() => {
                    this.$notify({
                        group: "notifications",
                        type: 'error',
                        title: "Fout opgetreden",
                        text: "Oeps er ging iets fout..",
                    }, 2000);
                }).finally(() => {
                    this.$store.dispatch('payment/setMutationId', null);
                });
            }
        },

        // redirect to payment page
        async paymentGoto (paymenttype) {
            let paymenttype_id = paymenttype;


            // set paymenttype_id when variable is object
            if ((typeof paymenttype) == 'object') {
                paymenttype_id = paymenttype.id;

                // is account required
                if (paymenttype.attributes.confirmation_required) {
                    this.$refs.paymenttypeconfirm.open(this.amount, paymenttype);
                    return;
                }

                // paymenttype has discount
                if (paymenttype.attributes.discount_id) {

                    // apply discount
                    await this.discountApply(paymenttype.relationships.discount);
                }

                // has one account
                // todo
                // if (paymenttype.relationships.accounts.data.length == 1 && !this.transaction.attributes.account_id) {
                //     await this.transactionSetAccount({
                //         account: paymenttype.relationships.accounts.data[0],
                //         paymenttype: paymenttype
                //     })
                // }

                // // has multiple accounts
                // if (paymenttype.relationships.accounts.data.length > 0 && !this.transaction.attributes.account_id) {
                //     this.$refs.account.open(paymenttype)
                //     return
                // }

                // is account required
                if (paymenttype.attributes.required_account && !this.transaction.attributes.account_id) {
                    this.$refs.account.open(paymenttype);
                    return;
                }



                // is wallet payment
                if (paymenttype.attributes.wallet_payment && this.amount > 0 && !this.$store.getters['payment/mutation_id']) {
                    this.$refs.walletpayment.open(this.amount, paymenttype);
                    return;
                }

                // is balance customer
                if (paymenttype.attributes.type == 'balance_customer' && !this.transaction.attributes.customer_id) {
                    // todo: select customer
                    this.$notify({
                        group: "notifications",
                        type: 'error',
                        title: "Geen klant geselecteerd",
                        text: "Afrekenvorm kan niet gebruikt worden zonder klant",
                    }, 2000);
                    return;
                }

                // is overpayment and is allowed
                if ((this.amount > this.balancedue) && !paymenttype.attributes.allow_overpayment) {
                    this.$notify({
                        group: "notifications",
                        type: 'error',
                        title: "Niet toegestaan",
                        text: "Afrekenvorm staat geen overbetaling toe",
                    }, 2000);
                    return;
                }

                // is amount negative and is allowed
                if ((this.amount < 0) && !paymenttype.attributes.allow_negativepayment) {
                    this.$notify({
                        group: "notifications",
                        type: 'error',
                        title: "Niet toegestaan",
                        text: "Afrekenvorm staat geen negatieve betaling toe",
                    }, 2000);
                    return;
                }
                // is amount is too high
                if (paymenttype.attributes.amount_lock_out_low && this.amount < paymenttype.attributes.amount_lock_out_low) {
                    this.$notify({
                        group: "notifications",
                        type: 'error',
                        title: "Niet toegestaan",
                        text: "Afrekenvorm staat geen betalingen lager dan " + paymenttype.attributes.amount_lock_out_low + " toe",
                    }, 2000);
                    return;
                }
                if (paymenttype.attributes.amount_lock_out_high && this.amount > paymenttype.attributes.amount_lock_out_high) {
                    this.$notify({
                        group: "notifications",
                        type: 'error',
                        title: "Niet toegestaan",
                        text: "Afrekenvorm staat geen betalingen hoger dan " + paymenttype.attributes.amount_lock_out_high + " toe",
                    }, 2000);
                    return;
                }



                // must ask for account
                if (!this.account_has_asked && paymenttype.attributes.askfor_account && !this.transaction.attributes.account_id) {
                    this.account_has_asked = true;
                    this.$refs.account.open();
                    return;
                }

                // is booking required
                if (paymenttype.attributes.required_bookingnumber && !this.transaction.attributes.booking_id) {
                    this.$refs.booking.open();
                    return;
                }
            }

            // set paymentdata
            this.$store.dispatch('payment/setPaymenttypeId', paymenttype_id);
            this.$store.dispatch('payment/setAmount', this.amount);

            this.$router.push({
                name: 'transaction.payment',
            });
        },

        paymenttypeAvailable (paymenttype) {

            if (this.transaction.attributes.type == 'cash_inout' && paymenttype.attributes.type == 'balance_customer') {
                return false;
            }

            if (!this.terminal.admin_mode && paymenttype.attributes.admin_only == true) {
                return false;
            }

            if (!paymenttype.relationships.discount) {
                return true;
            }

            if (this.transaction.relationships.payments.length > 0) {
                return false;
            }
            if (!this.transaction.attributes.type == 'cash_inout' && this.transaction.attributes.total != this.amount) {
                return false;
            }
            return true;
        },

        async paymenttypesGet () {

            let dataPaymenttypes = JSON.parse(localStorage.getItem('masterdata.paymenttypes'));

            if (!dataPaymenttypes) {
                try {
                    const response = await axios({
                        url: '/paymenttypes',
                        method: 'get',
                    });

                    dataPaymenttypes = response.data.data;
                    localStorage.setItem('masterdata.paymenttypes', JSON.stringify(dataPaymenttypes));

                } catch (error) {
                    localStorage.removeItem('masterdata.paymenttypes');
                    console.error(error);
                    return this.$notify({
                        group: "notifications",
                        type: 'error',
                        title: "Fout opgetreden",
                        text: "Oeps er ging iets fout..",
                    }, 2000);
                }
            }
            this.paymenttypes = dataPaymenttypes;
        },

        async transactionGet () {
            axios.get('/transactions/' + this.$store.getters['transaction/id']).then(response => {
                this.transaction = response.data.data;


            }).catch(() => {
                this.$notify({
                    group: "notifications",
                    type: 'error',
                    title: "Fout opgetreden",
                    text: "Oeps er ging iets fout..",
                }, 2000);
            })
                .finally(() => {
                    this.initialized = true;
                });
        },

        async transactionInit () {
            axios.post('/transactions', this.$store.getters['transaction/transaction']).then(response => {
                // set transaction
                this.transaction = response.data.data;

                // set transaction_id
                this.$store.dispatch('transaction/setId', this.transaction.id);

                // set the amounts
                this.amountGet().then(() => {

                    // preferred paymenttype used
                    if (this.$route.query.paymenttype_id > 0) {
                        this.paymentGoto(this.$route.query.paymenttype_id);
                        return;
                    }

                    // get the discounts
                    this.discountsGet();

                    // get the paymenttypes
                    this.paymenttypesGet();
                    this.initialized = true;
                });

            }).catch(() => {
                this.$notify({
                    group: "notifications",
                    type: 'error',
                    title: "Fout opgetreden",
                    text: "Oeps er ging iets fout..",
                }, 2000);
            }).finally(() => {
            });
        },

        async transactionSetAccount (data) {
            let account = data.account;
            let paymenttype = data.paymenttype;

            await axios.patch('/transactions/' + this.transaction.id + '/account', {
                account_id: account.id,
            }).then(async () => {
                // set transaction
                this.transaction.attributes.account_id = account.id;

                // set transaction_id
                this.$store.dispatch('transaction/setAccount', account);

                // apply discount
                if (account.relationships.discount) {
                    await this.discountApply(account.relationships.discount);
                }

                // continue to payment
                if (paymenttype) {
                    this.paymentGoto(paymenttype);
                }

            }).catch(() => {
                this.$notify({
                    group: "notifications",
                    type: 'error',
                    title: "Fout opgetreden",
                    text: "Oeps er ging iets fout..",
                }, 2000);
            }).finally(() => {
            });
        },

        async transactionSetBooking (booking) {
            axios.patch('/transactions/' + this.transaction.id + '/booking', {
                booking_id: booking.id,
            }).then(() => {
                // set transaction
                this.transaction.attributes.booking_id = booking.id;

                // set transaction_id
                this.$store.dispatch('transaction/setBooking', booking);

            }).catch(() => {
                this.$notify({
                    group: "notifications",
                    type: 'error',
                    title: "Fout opgetreden",
                    text: "Oeps er ging iets fout..",
                }, 2000);
            }).finally(() => {
            });
        },

        async transactionSetWallettoken (data) {

            // set the amount paid with the wallet
            this.amount = data.amount;
            this.$store.dispatch('payment/setAmount', this.amount);



            this.paymentGoto(data.paymenttype);
            return;
        },
    },

    computed: {
        terminal () {
            return this.$store.getters['terminal/terminal'];
        },
    },
};
</script>
