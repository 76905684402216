<template>
    <div v-if="active" class="fixed z-20 inset-0 overflow-y-auto">
        <div class="flex items-center justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
            <div class="fixed inset-0 transition-opacity" @click="close()">
                <div class="absolute inset-0 bg-gray-500 opacity-75"></div>
            </div>
            <span class="hidden sm:inline-block sm:align-middle sm:h-screen"></span>&#8203;

            <div id="modal" class="inline-block modal-extra-large align-bottom rounded-sm text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full" role="dialog" aria-modal="true" aria-labelledby="modal-headline">

                    <form @submit.prevent="onSubmit">
                        <div class="modal-content grid lg:grid-cols-3 gap-4">

                            <modal-close @close="close()"/>

                            <div class="text-base">
                                <div class="flex justify-between items-center mb-5">
                                    <div class="mt-3 text-center sm:mt-0 sm:text-left">
                                        <h3 class="modal-title text-lg leading-6 font-bold text-2xl" id="modal-headline">
                                            {{$t('translations.components.modal-account-create.new-account')}}
                                        </h3>
                                    </div>
                                </div>
                                <div class="mb-5 default-input">
                                    <label for="name" class="block mb-1">{{$t('translations.components.modal-account-create.name')}} *</label>
                                    <div class="mb-4">
                                        <input type="text" readonly @click="setField('name')" v-model="formData.name" name="name" 
                                        :class="{'account-error': errors.name}"
                                        class="py-1 px-2 block w-full shadow-sm border rounded-sm  focus:outline-none focus:ring-transparent" :placeholder="$t('translations.components.modal-account-create.name')" required/>
                                        <!-- <p class="text-red-500" v-if="errors.name">
                                            {{ errors.name }}
                                        </p> -->
                                    </div>
                                    <label for="streetname" class="block mb-1">{{$t('translations.components.modal-account-create.street-name')}} & {{$t('translations.components.modal-account-create.house-number')}} *</label>
                                    <div class="flex gap-4 mb-4 postcode-wrapper">
                                        <div>
                                            <input type="text" readonly @click="setField('zipcode')" v-model="formData.zipcode" name="zipcode" class="py-1 px-2 block w-full shadow-sm border rounded-sm  focus:outline-none focus:ring-transparent"
                                            :class="{'error': errors.zipcode}"
                                            :placeholder="$t('translations.components.modal-account-create.zip-code')" required/>
                                            <!-- <p class="text-red-500" v-if="errors.zipcode">
                                                {{ errors.zipcode }}
                                            </p> -->
                                        </div>
                                        <div>
                                            <input type="numeric" readonly @click="setField('housenumber')" v-model="formData.housenumber" name="housenumber" 
                                            :class="{'account-error': errors.housenumber}"
                                            class="py-1 px-2 block w-full shadow-sm border rounded-sm  focus:outline-none focus:ring-transparent" :placeholder="$t('translations.components.modal-account-create.house-number')" required/>
                                            <!-- <p class="text-red-500" v-if="errors.housenumber">
                                                {{ errors.housenumber }}
                                            </p> -->
                                        </div>

                                        <button type="button" class="button button-ghost mb-4" @click="getAddressInfo">
                                            {{$t('translations.components.modal-account-create.check-address')}}
                                        </button>
                                    </div>
                                
                                    <div class="flex gap-4 mb-4">
                                        <input type="text" readonly @click="setField('streetname')" v-model="formData.streetname" name="streetname" 
                                        :class="{'account-error': errors.streetname}"
                                        class="py-1 px-2 block w-full shadow-sm border rounded-sm focus:outline-none focus:ring-transparent" :placeholder="$t('translations.components.modal-account-create.street-name')" required/>
                                        <!-- <p class="text-red-500" v-if="errors.streetname">
                                            {{ errors.streetname }}
                                        </p> -->
                                    </div>
                                    <div class="mb-4 flex gap-4">
                                        <div class="flex-grow">
                                            <input type="text" readonly @click="setField('city')" v-model="formData.city" name="city" 
                                                :class="{'account-error': errors.city}"
                                                class="py-1 px-2 block w-full shadow-sm border rounded-sm  focus:outline-none focus:ring-transparent" :placeholder="$t('translations.components.modal-account-create.city')" required/>
                                            <!-- <p class="text-red-500" v-if="errors.city">
                                                {{ errors.city }}
                                            </p> -->
                                        </div>
                                        <div class="flex-grow">
                                            <input type="text" readonly @click="setField('country')" v-model="formData.country" name="country" 
                                                    :class="{'account-error': errors.country}"
                                                    class="py-1 px-2 block w-full shadow-sm border rounded-sm  focus:outline-none focus:ring-transparent" :placeholder="$t('translations.components.modal-account-create.country')"/>
                                            
                                            <!-- <p class="text-red-500" v-if="errors.country">
                                                {{ errors.country }}
                                            </p> -->
                                        </div>
                                    </div>
                                    <div class="bordered-section">
                                        <div>
                                            <label for="companyname" class="block mb-1">{{$t('translations.components.modal-account-create.company-info')}}</label>
                                            <div class="mb-4">
                                                <input type="text" readonly @click="setField('kvk')" v-model="formData.kvk" name="kvk" 
                                                    :class="{'account-error': errors.kvk}"
                                                    class="py-1 px-2 block w-full shadow-sm border rounded-sm  focus:outline-none focus:ring-transparent" :placeholder="$t('translations.components.modal-account-create.kvk')"/>
                                                <!-- <p class="text-red-500" v-if="errors.kvk">
                                                    {{ errors.kvk }}
                                                </p> -->
                                            </div>
                                        </div>
                                        <label for="phonenumber" class="block mb-1">{{ $t('translations.components.modal-account-create.contact-info') }} *</label>
                                        <div class="flex gap-4">
                                            <div class="flex-grow">
                                                <input type="text" readonly @click="setField('phonenumber')" v-model="formData.phonenumber" name="phonenumber" 
                                                :class="{'account-error': errors.phonenumber}"
                                                class="py-1 px-2 block w-full shadow-sm border rounded-sm  focus:outline-none focus:ring-transparent" :placeholder="$t('translations.components.modal-account-create.phone-number')" required/>
                                                <!-- <p class="text-red-500" v-if="errors.phonenumber">
                                                    {{ errors.phonenumber }}
                                                </p> -->
                                            </div>
                                            <div class="flex-grow">
                                                <input type="text" readonly @click="setField('email')" v-model="formData.email" name="email" 
                                                :class="{'account-error': errors.email}"
                                                class="py-1 px-2 block w-full shadow-sm border rounded-sm  focus:outline-none focus:ring-transparent" :placeholder="$t('translations.components.modal-account-create.email')" required/>
                                                <!-- <p class="text-red-500" v-if="errors.email">
                                                    {{ errors.email }}
                                                </p> -->
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <keyboard
                                class="lg:col-span-2 mt-16"
                                v-if="terminal.layout != 'mobileordering'"
                                v-model="input"
                                @input="changed"
                                :layouts="[
                            '1234567890{backspace:backspace}|qwertyuiop|asdfghjkl|{shift:goto:1}zxcvbnm_,.-|{space:space}',
                            '!@#$%^&*(){backspace:backspace}|QWERTYUIOP|ASDFGHJKL|{shift:goto:0}ZXCVBNM:;|{space:space}'
                        ]"
                                :maxlength="48"
                            ></keyboard>
                        </div>

                        <div class="modal-footer px-4 py-4 sm:px-6 sm:flex sm:flex-row-reverse">
                            <span class="mt-3 flex w-full rounded-sm  shadow-sm sm:mt-0 sm:w-auto">
                                <button type="submit" class="button button-filled button-large">
                                    {{$t('translations.components.modal-account-create.save')}}
                                </button>
                            </span>
                        </div>
                    </form>
            </div>
        </div>
    </div>
</template>

<script>

import axios from 'axios'
import * as yup from "yup";
import KeyboardHelper from '../mixins/keyboard.helper.js';

import ModalClose from './ModalClose.vue';

export default {
    mixins: [KeyboardHelper],
    components: {
        ModalClose,
    },
    data () {
        const schema = yup.object().shape({
            name: yup.string().required().max(255),
            streetname: yup.string().nullable().max(255),
            housenumber: yup.string().nullable().max(255),
            zipcode: yup.string().nullable().max(255),
            city: yup.string().nullable().max(255),
            country: yup.string().nullable().max(255),
            kvk: yup.string().nullable().max(255),
            phonenumber: yup.string().nullable().max(255),
            email: yup.string().nullable().max(255),
        });
        return {
            schema,
            active: false,
            formData: this.initialState(),
            errors: {}
        }
    },

    methods: {
        open() {
            this.active = true
        },

        close() {
            this.active = false
            this.formData = this.initialState();
            this.errors = {};
        },
        initialState(){
            return {
                name: '',
                streetname: '',
                housenumber: '',
                zipcode: '',
                city: '',
                country: '',
                kvk: '',
                phonenumber: '',
                email: ''
            }
        },
        validate(field) {
            this.schema.validateAt(field, this.formData).then(() => {
                this.errors[field] = "";
            }).catch(err => {
                this.errors[field] = err.message;
            });
        },

        onSubmit() {

            this.schema.validate(this.formData, { abortEarly: false }).then(() => {
                this.errors = {};
                axios.post('/accounts', this.formData).then(response => {
                    this.$emit('select', response.data.data);
                    this.close();
                    this.$notify({ group: "notifications", type: 'success', title: "Success", text: "Account added successfully" }, 2000);
                }).catch(error => {
                    this.$notify({ group: "notifications", type: 'error', title: "Fout opgetreden", text: "Oeps er ging iets fout.." }, 2000);
                    console.error(error)
                })
            }).catch(err => {
                err.inner.forEach(error => {
                    this.errors[error.path] = error.message;
                });
            });

        },

        getAddressInfo(){
            let address = {
                zipcode : this.formData.zipcode,
                housenumber: this.formData.housenumber,
            }

            axios.post('/get-address', address).then(response => {
                this.formData.streetname = response.data.streetname;
                this.formData.city = response.data.city;
                this.$i18n.locale == 'en' ? this.formData.country = 'Netherlands' : this.formData.country = 'Nederland';
            }).catch(error => {
                this.$notify({ group: "notifications", type: 'error', title: "Fout opgetreden", text: "Oeps er ging iets fout.." }, 2000);
                console.error(error)
            })
        },
        setField(field) {
            if(this.field !== field){
                this.validate(this.field);
            }
            this.field = field
        },
        changed(value) {
            if (value.length > 0)
                this.formData[this.field] += value
            else {
                this.formData[this.field] = this.formData[this.field].slice(0, -1)
            }
        },
    },

    computed: {
        terminal () {
            return this.$store.getters['terminal/terminal'];
        },
    },
}
</script>
