<template>

    <div :class="button_classes">
        <a @click="open()" class="button-anchor">
            {{$t('translations.components.modal-split-amount.title')}}
        </a>
    </div>
    <div v-if="active" class="fixed z-10 inset-0 overflow-y-auto">
        <div class="flex items-center justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
            <div class="fixed inset-0 transition-opacity" @click="close()">
                <div class="absolute inset-0 bg-gray-500 opacity-75"></div>
            </div>
            <span class="hidden sm:inline-block sm:align-middle sm:h-screen"></span>&#8203;
            <div id="modal" class="inline-block align-bottom bg-white rounded-sm text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full" role="dialog" aria-modal="true" aria-labelledby="modal-headline">
                <div class="modal-content">

                  <modal-close @close="close()"/>

                    <div class="sm:flex sm:items-start mb-5">
                        <div class="mt-3 text-center sm:mt-0 sm:text-left">
                            <h3 class="modal-title text-lg leading-6 font-bold text-2xl" id="modal-headline">
                                {{$t('translations.components.modal-split-amount.amount')}}
                            </h3>
                        </div>
                    </div>
                    <div>
                        <numpad :default="amount" :formatted="true" @inputchanged="amount = parseFloat($event / 100);" />
                    </div>
                </div>
                <div class="modal-footer p-4 sm:px-6 sm:flex sm:flex-row-reverse">
                    <span class="flex w-full rounded-sm  shadow-sm sm:ml-3 sm:w-auto">
                        <a @click="onSubmit()" class="button button-filled">
                            {{$t('translations.components.modal-split-amount.confirm')}}
                        </a>
                    </span>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
// import axios from 'axios'

import Numpad from './Numpad';
import ModalClose from './ModalClose.vue';

export default {
    props: ['default', 'button_classes'],
    emits: ['inputchanged', 'amountchanged'],
    components: {
        ModalClose,
        Numpad,
    },
    data () {
        return {
            amount: 0,
            active: false,
        }
    },
    methods: {
        open() {
            this.amount = this.default
            this.active = true
        },

        close() {
            this.active = false
        },

        onSubmit() {
            this.$emit('amountchanged', this.amount)
            this.close()
        },

    },
	computed: {
    }
}
</script>
