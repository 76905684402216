<template>
    <a class="cursor-pointer" :class="{'opacity-50': amount_paid > 0}" @click="open()">
        <svg class="w-10 h-10 sm:w-14 sm:h-14" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
            <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M6 18L18 6M6 6l12 12" />
        </svg>
    </a>

    <div v-if="active" class="fixed z-10 flex h-full items-center inset-x-0 px-4 pb-4 sm:inset-0 sm:flex sm:items-center sm:justify-center">
        <div class="fixed inset-0 transition-opacity" @click="close()">
            <div class="absolute inset-0 bg-gray-500 opacity-75"></div>
        </div>
        <div  id="modal" class="rounded-sm px-4 pt-5 pb-4 overflow-hidden shadow-xl transform transition-all sm:max-w-lg sm:w-full sm:p-6" role="dialog" aria-modal="true" aria-labelledby="modal-headline">

            <modal-close @close="close()"/>


            <div>
                <div class="mt-3 text-center sm:mt-5">
                    <h3 class="modal-title" id="modal-headline">
                        {{$t('translations.components.modal-transaction-cancel.title')}}
                    </h3>
                    <div class="mt-2">
                        <p class="text-base leading-5 text-left">
                            {{$t('translations.components.modal-transaction-cancel.confirm-question')}}
                        </p>
                    </div>
                </div>
            </div>
            <div class="mt-5">
                <span class="flex w-full rounded-sm  shadow-sm">
                    <a @click="cancelTransaction()" class="button button-danger button-large button-wide">
                        {{$t('translations.components.modal-transaction-cancel.yes-cancel')}}
                    </a>
                </span>
            </div>
        </div>
    </div>
</template>

<script>
import axios from 'axios'
import ModalClose from "./ModalClose";

export default {
    props: ['amount_paid', 'transaction_id'],
    components: {
      ModalClose,
    },
    data () {
        return {
            active: false,
        }
    },
    methods: {
        open() {
            if (this.amount_paid <= 0) {
                this.cancelTransaction()
                return
            }
            this.active = true
        },
        close() {
            this.active = false
        },

        async cancelTransaction() {

            axios.post('/transactions/' + this.transaction_id + '/cancel').catch(() => {
                this.$notify({ group: "notifications", type: 'error', title: "Fout opgetreden", text: "Oeps er ging iets fout.." }, 2000);
            }).finally(() => {
                // clear storage
                this.$store.dispatch('payment/clear');
                this.$store.dispatch('transaction/setId', null);
                this.$store.dispatch('transaction/setSubtotal', null);
                this.$store.dispatch('transaction/setType', 'postransaction');
                this.$store.dispatch('transaction/setCashfunction', null);

                // redirect back to pos
                // this.$router.go(-1)
                this.$router.push({name: 'landing'})
            })
        },
    },
	computed: {
		terminal() {
			return this.$store.getters['terminal/terminal'];
        },
    }
}
</script>
