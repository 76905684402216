<template>
    <div v-if="view == 'button'" @click="open()" class="flex items-center justify-center h-full w-full">
        {{$t("translations.components.modal-account.title")}}
    </div>

    <div v-if="active" class="fixed z-10 inset-0 overflow-y-auto">
        <div class="flex items-center justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
            <div class="fixed inset-0 transition-opacity" @click="close()">
                <div class="absolute inset-0 bg-gray-500 opacity-75"></div>
            </div>
            <span class="hidden sm:inline-block sm:align-middle sm:h-screen"></span>&#8203;
            <div id="modal" class="inline-block align-bottom rounded-sm text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full" role="dialog" aria-modal="true" aria-labelledby="modal-headline">
              <div class="modal-content text-base">

                <modal-close @close="close()"/>

                <div class="flex justify-between items-center mb-5">
                        <div class="mt-3 text-center sm:mt-0 sm:text-left">
                            <h3 class="modal-title text-lg leading-6 font-bold text-2xl" id="modal-headline">
                                {{$t("translations.components.modal-account.title")}}
                            </h3>
                        </div>
                    </div>

                    <div class="vue-keyboard relative">
                        <div class="flex justify-center">
                            <div class="border flex items-center justify-center h-7 w-8" :class="{'font-bold': filter == '0'}" @click="filter = '0'">0</div>
                            <div class="border flex items-center justify-center h-7 w-8" :class="{'font-bold': filter == '1'}" @click="filter = '1'">1</div>
                            <div class="border flex items-center justify-center h-7 w-8" :class="{'font-bold': filter == '2'}" @click="filter = '2'">2</div>
                            <div class="border flex items-center justify-center h-7 w-8" :class="{'font-bold': filter == '3'}" @click="filter = '3'">3</div>
                            <div class="border flex items-center justify-center h-7 w-8" :class="{'font-bold': filter == '4'}" @click="filter = '4'">4</div>
                            <div class="border flex items-center justify-center h-7 w-8" :class="{'font-bold': filter == '5'}" @click="filter = '5'">5</div>
                            <div class="border flex items-center justify-center h-7 w-8" :class="{'font-bold': filter == '6'}" @click="filter = '6'">6</div>
                            <div class="border flex items-center justify-center h-7 w-8" :class="{'font-bold': filter == '7'}" @click="filter = '7'">7</div>
                            <div class="border flex items-center justify-center h-7 w-8" :class="{'font-bold': filter == '8'}" @click="filter = '8'">8</div>
                            <div class="border flex items-center justify-center h-7 w-8" :class="{'font-bold': filter == '9'}" @click="filter = '9'">9</div>
                            <div class="border flex items-center justify-center h-7 w-24" @click="filter = ''">Clear</div>
                        </div>
                        <div class="flex justify-center">
                            <div class="border flex items-center justify-center h-7 w-8" :class="{'font-bold': filter == 'A'}" @click="filter = 'A'">A</div>
                            <div class="border flex items-center justify-center h-7 w-8" :class="{'font-bold': filter == 'B'}" @click="filter = 'B'">B</div>
                            <div class="border flex items-center justify-center h-7 w-8" :class="{'font-bold': filter == 'C'}" @click="filter = 'C'">C</div>
                            <div class="border flex items-center justify-center h-7 w-8" :class="{'font-bold': filter == 'D'}" @click="filter = 'D'">D</div>
                            <div class="border flex items-center justify-center h-7 w-8" :class="{'font-bold': filter == 'E'}" @click="filter = 'E'">E</div>
                            <div class="border flex items-center justify-center h-7 w-8" :class="{'font-bold': filter == 'F'}" @click="filter = 'F'">F</div>
                            <div class="border flex items-center justify-center h-7 w-8" :class="{'font-bold': filter == 'G'}" @click="filter = 'G'">G</div>
                            <div class="border flex items-center justify-center h-7 w-8" :class="{'font-bold': filter == 'H'}" @click="filter = 'H'">H</div>
                            <div class="border flex items-center justify-center h-7 w-8" :class="{'font-bold': filter == 'I'}" @click="filter = 'I'">I</div>
                            <div class="border flex items-center justify-center h-7 w-8" :class="{'font-bold': filter == 'J'}" @click="filter = 'J'">J</div>
                            <div class="border flex items-center justify-center h-7 w-8" :class="{'font-bold': filter == 'K'}" @click="filter = 'K'">K</div>
                            <div class="border flex items-center justify-center h-7 w-8" :class="{'font-bold': filter == 'L'}" @click="filter = 'L'">L</div>
                            <div class="border flex items-center justify-center h-7 w-8" :class="{'font-bold': filter == 'M'}" @click="filter = 'M'">M</div>
                        </div>
                        <div class="flex justify-center">
                            <div class="border flex items-center justify-center h-7 w-8" :class="{'font-bold': filter == 'N'}" @click="filter = 'N'">N</div>
                            <div class="border flex items-center justify-center h-7 w-8" :class="{'font-bold': filter == 'O'}" @click="filter = 'O'">O</div>
                            <div class="border flex items-center justify-center h-7 w-8" :class="{'font-bold': filter == 'P'}" @click="filter = 'P'">P</div>
                            <div class="border flex items-center justify-center h-7 w-8" :class="{'font-bold': filter == 'Q'}" @click="filter = 'Q'">Q</div>
                            <div class="border flex items-center justify-center h-7 w-8" :class="{'font-bold': filter == 'R'}" @click="filter = 'R'">R</div>
                            <div class="border flex items-center justify-center h-7 w-8" :class="{'font-bold': filter == 'S'}" @click="filter = 'S'">S</div>
                            <div class="border flex items-center justify-center h-7 w-8" :class="{'font-bold': filter == 'T'}" @click="filter = 'T'">T</div>
                            <div class="border flex items-center justify-center h-7 w-8" :class="{'font-bold': filter == 'U'}" @click="filter = 'U'">U</div>
                            <div class="border flex items-center justify-center h-7 w-8" :class="{'font-bold': filter == 'V'}" @click="filter = 'V'">V</div>
                            <div class="border flex items-center justify-center h-7 w-8" :class="{'font-bold': filter == 'W'}" @click="filter = 'W'">W</div>
                            <div class="border flex items-center justify-center h-7 w-8" :class="{'font-bold': filter == 'X'}" @click="filter = 'X'">X</div>
                            <div class="border flex items-center justify-center h-7 w-8" :class="{'font-bold': filter == 'Y'}" @click="filter = 'Y'">Y</div>
                            <div class="border flex items-center justify-center h-7 w-8" :class="{'font-bold': filter == 'Z'}" @click="filter = 'Z'">Z</div>
                        </div>
                    </div>

                    <div class="mt-2 overflow-x-auto list-secondary" style="height: 300px;">
                        <div class="flex flex-col">
                            <div class="overflow-x-auto" >
                                <div class="align-middle inline-block min-w-full">
                                    <table class="min-w-full divide-y divide-gray-200">
                                        <tbody>
                                            <!-- @foreach($accounts as $index => $account) -->
                                            <div v-for="(account, index) in filtered_accounts" @click="select(account)" :key="index" class="cursor-pointer flex justify-between items-center">
                                                <h3 class="text-xl">
                                                    {{ account.attributes.name }}
                                                    <span class="italic ml-3" v-if="account.relationships.discount">
                                                    ({{ account.relationships.discount.attributes.description }})
                                                    </span>
                                                </h3>
                                                <div class="text-right px-1 py-2 whitespace-no-wrap text-sm leading-5 font-medium text-gray-900">
                                                    <a class="button button-normal">
                                                        {{$t('translations.components.modal-account.select')}}
                                                    </a>
                                                </div>
                                            </div>
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div>
                    </div>
                </div>
                <div class="modal-footer flex items-center button-large">
                    <span class="flex-grow mr-4">
                        <a @click="select()" class="button button-ghost button-large">
                            {{$t('translations.components.modal-account.reset')}}
                        </a>
                    </span>
                    <span v-if="view == 'button'" class="flex-grow">
                        <a @click="$refs.accountCreate.open()" class="button button-filled button-large">
                            {{$t('translations.components.modal-account.new')}}
                        </a>
                    </span>
                </div>

            </div>
        </div>
    </div>
    <modal-account-create ref="accountCreate" @select="select($event)" />
</template>

<script>
import axios from 'axios';

// components
import ModalAccountCreate from './ModalAccountCreate.vue';
import ModalClose from './ModalClose.vue';

export default {
    props: ['view'],
    emits: ['selected'],
    components: {
        ModalAccountCreate,
        ModalClose,
    },
    data () {
        return {
            active: false,
            accounts: [],
            filter: null,
            paymenttype: null,
        }
    },

    methods: {
        open(paymenttype) {
            this.paymenttype = paymenttype

            if (this.paymenttype?.relationships.accounts.data.length > 0) {
                this.accounts = this.paymenttype.relationships.accounts.data
            } else {
                this.setAccounts();
            }
            this.active = true
        },

        close() {
            this.active = false
        },

        select(account) {
            this.$store.dispatch('transaction/setAccount', account)

            this.$emit('selected', {
                account: account,
                paymenttype: this.paymenttype
            })

            this.close()
        },

        async setAccounts() {
            axios.get('/accounts').then(response => {
                this.accounts = response.data.data
            }).catch(error => {
                this.$notify({ group: "notifications", type: 'error', title: "Fout opgetreden", text: "Oeps er ging iets fout.." }, 2000);
                console.error(error)
            })
        },
    },
	computed: {
		filtered_accounts() {
            if (this.filter) {
                return this.accounts.filter(account => {
                    return account.attributes.name.startsWith(this.filter)
                })
            }
            return this.accounts;
        },
    }
}
</script>
