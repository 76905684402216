<template>
    <div v-if="view == 'button'" @click="open()" class="button-anchor">
        <div class="flex justify-center">
            {{$t('translations.components.modal-booking.placeholder')}}
        </div>
    </div>

    <div v-if="active" class="fixed z-10 inset-0 overflow-y-auto">
        <div class="flex items-center justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
            <div class="fixed inset-0 transition-opacity" @click="close()">
                <div class="absolute inset-0 bg-gray-500 opacity-75"></div>
            </div>
            <span class="hidden sm:inline-block sm:align-middle sm:h-screen"></span>&#8203;
            <div id="modal" class="inline-block align-bottom rounded-sm text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full" role="dialog" aria-modal="true" aria-labelledby="modal-headline">

              <div class="modal-content">
                  <modal-close @close="close()"/>

                    <div class="flex justify-between items-center mb-5">
                        <div class="mt-3 text-center sm:mt-0 sm:text-left">
                            <h3 class="modal-title text-lg leading-6 font-bold text-2xl" id="modal-headline">
                                {{$t('translations.components.modal-booking.title')}}
                            </h3>
                        </div>
                    </div>

                    <div class="mt-2 overflow-x-auto" style="height: 300px;">
                        <div class="flex flex-col">
                            <div class="-my-2 py-2 overflow-x-auto" >
                                <div class="align-middle inline-block min-w-full">
                                    <table class="min-w-full divide-y divide-gray-200">
                                        <tbody>
                                            <tr v-for="(booking, index) in bookings" @click="select(booking)" :key="index" class="cursor-pointer">
                                                <td class="px-1 border-b py-2 whitespace-no-wrap text-sm leading-5 font-medium">
                                                    {{ booking.attributes.bookingnumber }}

                                                </td>
                                                <td class="px-1 border-b border-gray-200 py-2 whitespace-no-wrap text-sm leading-5 font-medium text-gray-900">
                                                    {{ booking.attributes.description }}
                                                </td>
                                                <td class="text-right px-1 border-b py-2 whitespace-no-wrap text-sm leading-5 font-medium text-gray-900">
                                                    <a class="button-default inline-flex items-center px-2.5 py-2 border border-gray-300 text-xs leading-4 font-medium rounded-sm text-gray-700 bg-white hover:text-gray-500 focus:outline-none focus:border-blue-300 focus:shadow-outline-blue active:text-gray-800 active:bg-gray-50 transition ease-in-out duration-150">
                                                        {{$t('translations.components.modal-booking.select')}}
                                                    </a>
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div>
                    </div>
                </div>
                <div class="modal-footer flex">
                    <a @click="select()" class="button button-danger button-wide button-large">
                        {{$t('translations.components.modal-booking.reset')}}
                    </a>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import axios from 'axios'
import ModalClose from "./ModalClose";


export default {
    props: ['view'],
    emits: ['selected', 'opened'],
    components: {
      ModalClose,
    },
    data () {
        return {
            active: false,
            bookings: [],
        }
    },

    methods: {
        open() {
            this.setBookings();
            this.active = true
            this.$emit('opened')
        },

        close() {
            this.active = false
        },

        select(booking) {
            this.$store.dispatch('transaction/setBooking', booking)

            this.$emit('selected', booking)

            this.close()
        },

        async setBookings() {
            axios.get('/bookings').then(response => {
                this.bookings = response.data.data
            }).catch(error => {
                this.$notify({ group: "notifications", type: 'error', title: "Fout opgetreden", text: "Oeps er ging iets fout.." }, 2000);
                console.error(error)
            })
        },
    },
}
</script>
